import { Button, Col, Form, Row, UploadFile } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useState } from 'react'
import DocumentUploader from 'src/components/templates/TemplateDocumentUploader'
import { userMessages } from 'src/constants/userMessages'
import { showErrorMessage, showSuccessMessage } from 'src/helpers/messageHelper'
import { DocumentType } from 'src/types/enums/DocumentType'

interface IImportFromJsonFormProps {
    onSubmit: (formValues?: any) => Promise<void>
    onCancel?: () => void
}

const ImportFromJsonForm = ({ onSubmit, onCancel }: IImportFromJsonFormProps) => {
    const [form] = useForm()
    const [file, setFile] = useState<UploadFile | null>(null)
    const [uploading, setUploading] = useState(false)

    const fetchFileFromUploader = (file: UploadFile | null) => {
        setFile(file)
        form.setFieldValue('importFile', file)

        //will make the file is required error clear up but won't make it show up before submission
        if (file) form.validateFields(['importFile'])
    }

    const handleSubmit = async () => {
        if (file) {
            const allFormValues = form.getFieldsValue(true)
            try {
                setUploading(true)
                await onSubmit(allFormValues)
                setUploading(false)
                showSuccessMessage(userMessages.importJobCreated)
            } catch (error) {
                setUploading(false)
                showErrorMessage(`${file.name} Document upload failed.`)
            }
        }
    }

    return (
        <div>
            <Form form={form} name="import-from-json-form" onFinish={handleSubmit} onReset={() => form.resetFields()}>
                <Form.Item name="importFile">
                    <DocumentUploader documentType={DocumentType.Json} onFileChange={fetchFileFromUploader} />
                </Form.Item>
                <Form.Item>
                    <Row justify="center" gutter={24}>
                        <Col span={8}>
                            <Button type="primary" ghost onClick={onCancel} style={{ width: '100%' }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Button htmlType="submit" loading={uploading} style={{ width: '100%' }}>
                                {uploading ? 'Uploading' : 'Import Entities'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ImportFromJsonForm
