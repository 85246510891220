import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import { Space, message } from 'antd'
import styles from './messageHelper.module.css'
import { poppins } from './themeHelper'

export const showSuccessMessage = (successMsg: string) => {
    return message.open({
        className: styles['messageContainerGlobal'],
        content: (
            <div className={styles['successMessageContainerCustom']}>
                <Space>
                    <span className={styles['messageIcon']}>
                        <CheckOutlined />
                    </span>
                    <span className={styles['messageText']} style={poppins.style}>
                        {successMsg}
                    </span>
                </Space>
            </div>
        ),
    })
}

export const showErrorMessage = (successMsg: string) => {
    return message.open({
        className: styles['messageContainerGlobal'],
        content: (
            <div className={styles['errorMessageContainerCustom']}>
                <Space>
                    <span className={styles['messageIcon']}>
                        <WarningOutlined />
                    </span>
                    <span className={styles['messageText']} style={poppins.style}>
                        {successMsg}
                    </span>
                </Space>
            </div>
        ),
    })
}
