export const userMessages = {
    placeholderAdded: 'Placeholder added',
    placeholderAddFailed: 'Placeholder add failed',
    placeholderDeleted: 'Placeholder deleted',
    placeholderDeleteFailed: 'Placeholder delete failed',
    presentationTemplateDeleted: 'Template deleted',
    presentationTemplateDeleteFailed: 'Template delete failed',
    presentationTemplateAdded: 'Presentation template added',
    presentationGroupCreated: 'Presentation Group Created',
    presentationGroupCreationFailed: 'Presentation Group Creation Failed',
    presentationGroupEdited: 'Presentation group edited',
    presentationGroupEditFailed: 'Presentation group edit failed',
    presentationGroupDeleted: 'Presentation group deleted',
    presentationGroupDeleteFailed: 'Presentation group deletion failed',
    presentationTemplateUploadFailed: 'Presentation document upload failed',
    presentationTemplateUnsupportedFiletype: 'The template document must be a .pptx file.',
    contractTemplateAdded: 'Template added',
    contractTemplateUnsupportedFiletype: 'The template document must be a .pdf or .docx  file.',
    contractGroupCreated: 'Contract group created',
    contractGroupCreationFailed: 'Contract group Creation Failed',
    contractGroupEdited: 'Contract group edited',
    contractGroupEditFailed: 'Contract group Editing failed',
    changesSaved: 'Changes saved',
    saveChangesFailed: 'Changes save failed',
    conditionAdded: 'Condition added',
    conditionAddFailed: 'Condition add failed',
    conditionEdited: 'Condition Edited',
    conditionEditFailed: 'Condition Edit Failed',
    conditionDeleted: 'Condition deleted',
    conditionDeleteFailed: 'Condition delete failed',
    importOperationUnsupportedFiletype: 'Import operation file must be a Json',
    importJobCreated: 'Selected group(s) will be imported in the background.',
}
