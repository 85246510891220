import { InboxOutlined } from '@ant-design/icons'
import { Upload, UploadProps } from 'antd'
import { UploadFile } from 'antd/es/upload'
import { useState } from 'react'
import { userMessages } from 'src/constants/userMessages'
import { showErrorMessage } from 'src/helpers/messageHelper'
import { DocumentType } from 'src/types/enums/DocumentType'
interface IDocumentUploaderProps {
    onFileChange: (file: UploadFile | null) => void
    documentType: DocumentType
    //templateId?: string //it will be used to update the document of the given template if passed
}

let acceptedExtensions = ''
let acceptedFileTypes: string[] = []

function DocumentUploader({ onFileChange, documentType }: IDocumentUploaderProps) {
    const [fileList, setFileList] = useState<UploadFile[]>([])

    switch (documentType) {
        case DocumentType.Contract:
            acceptedExtensions = '.docx,.pdf'
            acceptedFileTypes = [
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]
            break

        case DocumentType.Presentation:
            acceptedExtensions = '.pptx'
            acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.presentationml.presentation']
            break

        case DocumentType.Json:
            acceptedExtensions = '.json'
            acceptedFileTypes = ['application/json']
    }

    const supportedFileTypesMessage = (typeOfDocument: DocumentType) => {
        switch (typeOfDocument) {
            case DocumentType.Contract:
                return (
                    <ul>
                        <li>.docx for editable contract and bank data templates</li>
                        <li>.pdf and .docx for terms of service documents</li>
                    </ul>
                )
                break

            case DocumentType.Presentation:
                return (
                    <ul>
                        <li>.pptx for editable presentation templates</li>
                    </ul>
                )
                break
            case DocumentType.Json:
                return (
                    <ul>
                        <li>.json for importing docTemplate entities</li>
                    </ul>
                )

            default:
                return (
                    <ul>
                        <li>.docx for editable contract and bank data templates</li>
                        <li>.pdf for terms of service documents</li>
                        <li>.pptx for editable presentation templates</li>
                        <li>.json for importing docTemplate entities</li>
                    </ul>
                )

                break
        }
    }

    const uploadProps: UploadProps = {
        name: 'file-uploader',
        accept: acceptedExtensions,
        multiple: false,
        fileList: fileList,
        beforeUpload() {
            //prevents automatic upload
            return false
        },
        onChange(info) {
            const fileType = info.file.type as string
            if (!acceptedFileTypes.includes(fileType)) {
                let errorMsg = 'Error'
                switch (documentType) {
                    case DocumentType.Contract:
                        errorMsg = userMessages.contractTemplateUnsupportedFiletype
                        break
                    case DocumentType.Presentation:
                        errorMsg = userMessages.presentationTemplateUnsupportedFiletype
                        break
                    case DocumentType.Json:
                        errorMsg = userMessages.importOperationUnsupportedFiletype
                        break
                }

                showErrorMessage(errorMsg)
            } else if (info.file.status !== 'removed') {
                //limiting maximum number of files to 1
                setFileList([info.file])
                onFileChange(info.file)
            } else {
                setFileList([])
                onFileChange(null)
            }
        },
    }

    return (
        <div>
            <h3>Upload a file</h3>
            <div>
                DocTemplate accepts:
                {supportedFileTypesMessage(documentType)}
            </div>
            <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single template file upload. Strictly prohibited from uploading company data or other
                    banned files.
                </p>
            </Upload.Dragger>
        </div>
    )
}

export default DocumentUploader
